<template>
    <div class="row justify-content-around">
        <div class="col-12">
            <div class="d-flex">
                <input type="text" class="form-control" placeholder="email" v-model="tableFilter">
                <button class="mx-3 btn btn-lg btn-current" @click="findStudent()">Search</button>
            </div>
        </div>
        <div class="card-body w-100">
            <div class="row">
                <div v-if="!isLoad" class="col-12 text-center py-3">
                    <div class="table-responsive mw-100 pr-2 ml-0">
                        <datatable :class="'table table-hover table-bordered'" :columns="columns" :filter="tableFilter" :data="data" :page="1" :perPage="10">
                            <template name="default" slot-scope="{ row, index }">
                                <tr v-if="row">
                                    <td>{{ index+(page*queryLimit)-9 }}</td>
                                    <td>{{ row.user.name }}</td>
                                    <td>{{ row.user.email }}</td>
                                    <td>{{ row.user.username }}</td>
                                    <td>
                                        <router-link :to="{name: 'SkillsInfoEvaluationCoursesAdmin', params:{idCourse: paramsId, idStudent:row.user.id}}"><i class="fas fa-info font-xs text-primary-500 mr-4"></i></router-link>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="8">Nothing to see here</td>
                                </tr>
                            </template>
                        </datatable>
                    </div>
                    <div class="d-flex justify-content-end">
                        <div class="icon-button py-2 mx-2" v-if="page > 1" @click="handleChangePage(-1)">
                            <i class="feather-chevron-left"></i>
                        </div>
                        <div class="icon-button py-2 mx-2" v-if="data.length >= queryLimit" @click="handleChangePage(1)">
                            <i class="feather-chevron-right"></i>
                        </div>
                    </div>
                    <!-- <datatable-pager v-model="page" type="abbreviated"></datatable-pager> -->
                </div>
                <div v-else class="col-12 text-center py-3">
                    <div class="my-3">
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="spinner-grow text-warning mx-3" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: 'Everyone',
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            isLoad: true,
            role_id: '',
            data: [],
            page: 1,
            tableFilter: '',
            columns: [
                {label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Name', field: 'user.name', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Email', field: 'user.email', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},
                {label: 'Username', field: 'user.username', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false}, 
                {label: 'Option', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false},                
            ],
            queryLimit: 10,
            queryOffset: 0
        }
    },
    created(){
        this.getEverone()
    },
    methods:{
        async getEverone(){
            this.isLoad = true
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/people?slug=${this.paramsId}&limit=${this.queryLimit}&offset=${this.queryOffset}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.data = res.data.data
            })
            this.isLoad = false
        },
        handleChangePage(pageSum){
            this.page = this.page + pageSum
            this.queryOffset = this.queryLimit * this.page
            this.getEverone()
        },
        async findStudent(){
            this.isLoad = true
            if(this.tableFilter){
                await axios.get(`${process.env.VUE_APP_URL_API}/admin/people/search?slug=${this.paramsId}&email=${this.tableFilter}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.data = res.data.data
                }).catch(err => {
                    console.error(err)
                    this.data = []
                })
            }else {
                await this.getEverone()
            }
            this.isLoad = false
        }
    },
    watch: {
        role_id(){
            this.getEverone()
        }
    }
}
</script>