<template>
    <div class="row justify-content-center mt-4">
        <div class="col-lg-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-between product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                    <li class="list-inline-item"><a :class="{'active': tab == '#knowledge'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 ml-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#knowledge">Knowledge</a></li>
                    <li class="list-inline-item"><a :class="{'active': tab == '#skills'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#skills">Skills and Behavior</a></li>
                    <li class="list-inline-item"><a :class="{'active': tab == '#attitudes'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#attitudes">Attitudes</a></li>
                    <li class="list-inline-item"><a :class="{'active': tab == '#reward'}" class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 mr-sm-5 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#reward">Reward &amp; Recognition</a></li>
                </ul>
            </div>
            <div class="tab-content">
                <div class="tab-pane fade show active" v-if="tab == '#knowledge'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around mb-2">
                            <div class="card-body pb-0"><h2 class="fw-400 font-lg d-block"><b>Knowledge</b></h2></div>
                        </div>
                        <div class="card-body p-0">
                            <Knowledge></Knowledge>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade show active" v-if="tab == '#skills'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around mb-2">
                            <div class="card-body pb-0"><h2 class="fw-400 font-lg d-block"><b>Skills and Behavior</b></h2></div>
                        </div>
                        <div class="card-body p-0">
                            <Skills></Skills>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade show active" v-if="tab == '#attitudes'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around mb-2">
                            <div class="card-body pb-0"><h2 class="fw-400 font-lg d-block"><b>Attitudes</b></h2></div>
                        </div>
                        <div class="card-body p-0">
                            <Attitude></Attitude>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade show active" v-if="tab == '#reward'">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <div class="row justify-content-around mb-2">
                            <div class="card-body pb-0"><h2 class="fw-400 font-lg d-block"><b>Reward &amp; Recognition</b></h2></div>
                        </div>
                        <div class="card-body p-0">
                            <RewardRecognition></RewardRecognition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Knowledge from './Sub/Knowledge.vue'
import Skills from './Sub/Skills.vue'
import Attitude from './Sub/Attitude.vue'
import RewardRecognition from './Sub/RewardRecognition.vue'
export default {
    components: {
        Knowledge,
        Skills,
        Attitude,
        RewardRecognition
    },
    mounted() {
        if (this.$route.hash) {
            this.tab = this.$route.hash
        }
    },
    data() {
        return{
            paramsId: this.$route.params.idCourse,
            tab: '#knowledge'
        }
    },
}
</script>
